import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FollowService {
  MAX_FOLLOWING_LIMIT = 50;

  constructor(private afs: AngularFirestore) {}

  followUser(userId: string, followId: string) {
    return this.afs
      .doc(`users/${userId}`)
      .valueChanges()
      .pipe(
        take(1),
        map((user: any) => {
          if (!user.following || user.following.length < this.MAX_FOLLOWING_LIMIT) {
            return this.afs.doc(`users/${userId}`).update({
              following: firebase.firestore.FieldValue.arrayUnion(followId),
            });
          } else {
            throw new Error('You have reached the maximum number of follows');
          }
        })
      );
  }

  unfollowUser(userId: string, followId: string) {
    return this.afs
      .doc(`users/${userId}`)
      .valueChanges()
      .pipe(
        take(1),
        map((user: any) => {
          return this.afs.doc(`users/${userId}`).update({
            following: firebase.firestore.FieldValue.arrayRemove(followId),
          });
        })
      );
  }

  getFollowing(userId: string) {
    return this.afs
      .doc(`users/${userId}`)
      .valueChanges()
      .pipe(
        take(1),
        map((user: any) => user.following)
      );
  }

  getFollowers(userId: string) {
    return this.afs.collection('users', (ref) => ref.where('following', 'array-contains', userId)).valueChanges();
  }
}
