import { Title } from '@angular/platform-browser';
import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { MatDialog } from '@angular/material/dialog';
import { UploadComponent } from '@app/uploads/upload/upload.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  isSticky: boolean = false;
  public selectedDate = new Date().setHours(0, 0, 0, 0);
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }
  icon_url: string;
  hasToggledTheme: boolean | string = false;
  localTheme = localStorage.getItem('darkTheme');

  constructor(
    private router: Router,
    private titleService: Title,
    public authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private media: MediaObserver,
    private dialog: MatDialog,
    private overlay: OverlayContainer
  ) {}

  ngOnInit() {
    console.log(this.title);
    this.parseIcon(this.title);
    console.log(this.icon_url);
    console.log(this.localTheme);

    if (this.localTheme === 'true') {
      this.hasToggledTheme = true;
      this.overlay.getContainerElement().classList.add('dark-theme');
    } else {
      this.hasToggledTheme = false;
      this.overlay.getContainerElement().classList.remove('dark-theme');
    }
  }

  toggleTheme() {
    this.hasToggledTheme = !this.hasToggledTheme;
    localStorage.setItem('darkTheme', this.hasToggledTheme.toString());
    let darkClassName = 'dark-theme';
    if (this.hasToggledTheme) {
      console.log('isdark');
      this.overlay.getContainerElement().classList.add(darkClassName);
    } else {
      console.log('isLight');
      this.overlay.getContainerElement().classList.remove(darkClassName);
    }
  }

  logout() {
    this.authenticationService.SignOut();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  upload() {
    this.dialog
      .open(UploadComponent, {
        width: '90%',
        height: '80%',
        panelClass: 'custom-modalbox',
      })
      .afterClosed()
      .subscribe((v) => console.log(v, 'Closed Upload Dialog'));
  }

  parseIcon(string: any) {
    console.log(string);
    switch (string) {
      case 'Feed': {
        this.icon_url = '/assets/feed.png';
        break;
      }
      case 'Home': {
        this.icon_url = '/assets/home.png';
        break;
      }
      default: {
        this.icon_url = '/assets/ff-logos/3.png';
        break;
      }
    }
  }

  passIcon() {
    this.parseIcon(this.title);
  }

  calorieTracker() {
    this.router.navigateByUrl(`${this.selectedDate}`);
  }
}
