<div class="col2 margin1">
  <button color="primary" mat-stroked-button (click)="loadUpload('photos')">
    <mat-icon>photo_gallery</mat-icon>Photos
  </button>
  <!-- <button color="primary" mat-stroked-button (click)="loadUpload('videos')">
    <mat-icon>videocamera</mat-icon>Videos
  </button> -->
  <button color="primary" mat-stroked-button (click)="loadUpload('posts')"><mat-icon>notes</mat-icon>Posts</button>
</div>
<div class="vmargin1 scroll-view">
  <!-- Photos -->
  <div class="col1">
    <cdk-virtual-scroll-viewport itemSize="10">
      <ng-container *ngIf="photosLoaded">
        <!-- <mat-chip-list class="cola" color="primary">
          <mat-chip selected (click)="filterPhotos('all')">All</mat-chip>
          <mat-chip (click)="filterPhotos('fitness')">Fitness</mat-chip>
          <mat-chip (click)="filterPhotos('food')">Food</mat-chip>
          <mat-chip (click)="filterPhotos('social')">Social</mat-chip>
        </mat-chip-list> -->
        <div class="col1 margin1">
          <button mat-raised-button [matMenuTriggerFor]="photoFilter" aria-label="Filter Photos">
            <mat-icon>more_vert</mat-icon> Filter
          </button>
          <mat-menu #photoFilter="matMenu">
            <button mat-menu-item (click)="filterPhotos('all')">
              <mat-icon>photo</mat-icon>
              <span>All</span>
            </button>
            <button mat-menu-item (click)="filterPhotos('fitness')">
              <mat-icon>fitness_center</mat-icon>
              <span>Fitness</span>
            </button>
            <button mat-menu-item (click)="filterPhotos('food')">
              <mat-icon>dinner_dining</mat-icon>
              <span>Food</span>
            </button>
            <button mat-menu-item (click)="filterPhotos('social')">
              <mat-icon>emoji_people</mat-icon>
              <span>Social</span>
            </button>
          </mat-menu>
        </div>
        <div class="cards">
          <div *ngFor="let photo of photos | searchFilter: query">
            <mat-card class="col1 vmargin1">
              <mat-card-header>
                <!-- <mat-card class="image-result-skeleton-card mat-elevation-z2" *ngFor="let i of [1, 2, 3]"> -->
                <!-- </mat-card> -->

                <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="photo?.title" /> -->

                <mat-card-title [routerLink]="['/profile', photo.uid]">@{{ photo?.username }}</mat-card-title>
                <mat-card-subtitle>{{ fromNow(photo?.date) }}</mat-card-subtitle>
              </mat-card-header>
              <img
                *ngIf="photo?.photoUrl"
                lazyImage
                mat-card-image
                class="mat-elevation-z2"
                [src]="photo?.photoUrl"
                [alt]="photo?.title"
              />

              <mat-card-content class="margin-unset">
                <p>
                  {{ photo?.description }}
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(photo, 'photo')">
                  <mat-icon>comment</mat-icon>
                </button>
                <!-- <button
                  *ngIf="!liked"
                  mat-stroked-button
                  color="primary"
                  type="button"
                  aria-label="Example icon button with a heart icon"
                  (click)="like(photo.id)"
                >
                  <mat-icon>favorite</mat-icon>
                  {{ photos.likes?.length }}
                </button>
                <button
                  *ngIf="liked"
                  mat-stroked-button
                  color="warn"
                  type="button"
                  aria-label="Example icon button with a heart icon"
                  (click)="unlike(photo.id)"
                >
                  <mat-icon>favorite</mat-icon>
                  {{ photo?.likes?.length }}
                </button> -->
                <div class="col1 delete-btn vmargin1" *ngIf="photo?.uid === user?.uid">
                  <button mat-stroked-button (click)="deletePhoto(photo)" color="warn">
                    <mat-icon>delete</mat-icon>Delete
                  </button>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div *ngIf="photos.length <= 0 && !loader">
          <span class="mat-h2">
            <p class="center">No Photos. Add one!</p>
          </span>
        </div>
      </ng-container>
      <!-- End Photos -->

      <!-- Videos -->
      <!-- <ng-container *ngIf="videosLoaded">
        <div class="col1 margin1">
          <button mat-raised-button [matMenuTriggerFor]="videoFilter" aria-label="Filter Videos">
            <mat-icon>more_vert</mat-icon> Filter
          </button>
          <mat-menu #videoFilter="matMenu">
            <button mat-menu-item (click)="filterVideos('all')">
              <mat-icon>videocamera</mat-icon>
              <span>All</span>
            </button>
            <button mat-menu-item (click)="filterVideos('fitness')">
              <mat-icon>fitness_center</mat-icon>
              <span>Fitness</span>
            </button>
            <button mat-menu-item (click)="filterVideos('food')">
              <mat-icon>dinner_dining</mat-icon>
              <span>Food</span>
            </button>
            <button mat-menu-item (click)="filterVideos('social')">
              <mat-icon>emoji_people</mat-icon>
              <span>Social</span>
            </button>
          </mat-menu>
        </div>
        <div class="col1">
          <div *ngFor="let videos of videos | searchFilter: query; let i = index">
            <mat-card class="col1 margin-unset">
              <mat-card-header>


                <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="videos?.title" />

                <mat-card-title>{{ parseDisplayEmail(videos?.email) }}</mat-card-title>
                <mat-card-subtitle>{{ fromNow(videos?.date) }}</mat-card-subtitle>
                <div fxFlex></div>

                <button mat-icon-button color="primary" (click)="toggleMute(i)">
                  <mat-icon color="primary" *ngIf="videosMuted && i == videoId">headset_off</mat-icon>
                  <mat-icon color="primary" *ngIf="!videosMuted && i == videoId">headset</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="toggleVideo(i)">
                  <mat-icon color="primary" *ngIf="!videoPlaying && i == videoId">play_circle_outline</mat-icon>
                  <mat-icon color="primary" *ngIf="videoPlaying && i == videoId">pause</mat-icon>
                </button>
              </mat-card-header>
              <video
                playsinline
                [muted]="'muted'"
                preload="metadata"
                [id]="i"
                lazyImage
                mat-card-image
                class="mat-elevation-z2"
                width="100%"
                height="450px"
                loop
              >

                <source *ngIf="videos?.videoUrl" [src]="videos?.videoUrl" type="video/mp4" />
                <source *ngIf="videos?.videoUrl" [src]="videos?.videoUrl" type="video/ogg" />
                Your browser does not support the video tag.
              </video>

              <mat-card-content class="margin-unset">
                <p>
                  {{ videos?.description }}
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(videos, 'video')">
                  <mat-icon>comment</mat-icon> 0
                </button>
                <button
                  mat-stroked-button
                  color="warn"
                  type="button"
                  aria-label="Example icon button with a heart icon"
                >
                  <mat-icon>favorite</mat-icon>
                  0
                </button>
                <div class="col1 delete-btn vmargin1" *ngIf="videos?.uid === user?.uid">
                  <button mat-stroked-button (click)="deleteVideo(videos)" color="warn">
                    <mat-icon>delete</mat-icon>Delete
                  </button>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <div *ngIf="videos.length <= 0">
            <span class="mat-h2">
              <p class="center">No Videos. Add one!</p>
            </span>
          </div>
          <ng-container *ngIf="loader">
            <div class="col1 margin2">
              <app-loader [isLoading]="loader" [message]="message" [size]="2"></app-loader>
            </div>
          </ng-container>
        </div>
      </ng-container> -->
      <!-- End Videos -->

      <!-- Posts -->
      <ng-container *ngIf="postsLoaded">
        <div class="col1 margin1">
          <button mat-raised-button [matMenuTriggerFor]="postsFilter" aria-label="Filter Posts">
            <mat-icon>more_vert</mat-icon> Filter
          </button>
          <mat-menu #postsFilter="matMenu">
            <button mat-menu-item (click)="filterPosts('all')">
              <mat-icon>notes</mat-icon>
              <span>All</span>
            </button>
            <button mat-menu-item (click)="filterPosts('fitness')">
              <mat-icon>fitness_center</mat-icon>
              <span>Fitness</span>
            </button>
            <button mat-menu-item (click)="filterPosts('food')">
              <mat-icon>dinner_dining</mat-icon>
              <span>Food</span>
            </button>
            <button mat-menu-item (click)="filterPosts('social')">
              <mat-icon>emoji_people</mat-icon>
              <span>Social</span>
            </button>
          </mat-menu>
        </div>
        <div class="cards">
          <div *ngFor="let post of posts | searchFilter: query">
            <mat-card class="col1 mat-elevation-z2">
              <mat-card-header>
                <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="post?.title" /> -->

                <mat-card-title>@{{ post?.username }}</mat-card-title>
                <mat-card-subtitle>{{ fromNow(post?.date) }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content class="margin-unset">
                <span class="mat-h4 margin-unset">
                  <cite> {{ post?.description }}</cite>
                </span>
              </mat-card-content>
              <mat-card-actions>
                <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(post, 'post')">
                  <mat-icon>comment</mat-icon>
                </button>
                <!-- <button
                  mat-stroked-button
                  color="warn"
                  type="button"
                  aria-label="Example icon button with a heart icon"
                >
                  <mat-icon>favorite</mat-icon>
                  2000
                </button> -->
                <div class="col1 delete-btn vmargin1" *ngIf="post?.uid === post?.uid">
                  <button mat-stroked-button (click)="deletePost(post)" color="warn">
                    <mat-icon>delete</mat-icon>Delete
                  </button>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div *ngIf="posts.length <= 0 && !loader">
          <span class="mat-h2">
            <p class="center">No Posts. Add one!</p>
          </span>
        </div>
      </ng-container>
      <!-- <ng-template #loadingposts> -->
      <ng-container *ngIf="loader">
        <div class="col1 margin3 center">
          <app-loader [isLoading]="loader" [message]="message" [size]="2"></app-loader>
        </div>
      </ng-container>
      <!-- </ng-template> -->
      <!-- End Posts -->
    </cdk-virtual-scroll-viewport>
  </div>
</div>
