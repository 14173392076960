import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(public authService: AuthenticationService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['login']);
    } else if (this.authService.isLoggedIn && !this.authService.hasUsername) {
      this.router.navigate(['/username']);
    }
    // else if (this.authService.isLoggedIn && this.authService.isAdmin !== true) {
    //   this.router.navigate(['/home']);
    //   return true;
    // }
    else {
      return true;
    }
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAdmin !== true) {
      this.router.navigate(['home']);
      return false;
    } else if (this.authService.isAdmin) {
      return true;
    }
  }
}
