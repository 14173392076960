import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@core';
import { AuthenticationService } from '../authentication.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { pipe } from 'rxjs';
const log = new Logger('Signup');

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  signUpForm!: FormGroup;
  isLoading = false;
  usernameAvailable: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private afs: AngularFirestore
  ) {
    this.createForm();
    // this.signUpForm.valueChanges.subscribe(
    //   pipe((v: any) => {
    //     const username = v.username;

    // if (username.length >= 3 && username.length <= 15) {
    // const ref: any = this.afs.doc(`usernames/${username}`);
    // const { exists } = ref.get();
    // this.usernameAvailable = !exists;
    // }
    //   }),
    //   debounceTime(500)
    // );
  }

  ngOnInit() {}

  private createForm() {
    this.signUpForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          // Validators.pattern('/@|(altour)|(swifttrip)|(internova)|(travelleaders)|(tlccorporate).coms*$/'),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          Validators.minLength(6),
          Validators.maxLength(25),
        ],
      ],
    });
  }

  async signUp() {
    const { email, password } = this.signUpForm.value;

    this.isLoading = true;
    console.log(email, password);
    const signup$ = this.authenticationService.SignUp(email, password);
    return await signup$
      .then((res: any) => {
        this.signUpForm.markAsPristine();
        this.isLoading = false;
        console.log(res, 'res');
        log.debug(`successfully signed up`);
      })
      .catch((e: any) => {
        log.debug(`Login error: ${e}`);
        this.error = e;
      });
  }

  // // Get the current user
  // const user = this.authenticationService.userData;

  // // Create refs for both documents
  // const userDoc: any = this.afs.doc(`users/${user.uid}`);
  // const usernameDoc: any = this.afs.doc(`usernames/${this.signUpForm.value.username}`);

  // // Commit both docs together as a batch write.
  // let username = this.signUpForm.value.username;
  // const batch = this.afs.firestore.batch();
  // batch.set(userDoc, { username });
  // batch.set(usernameDoc, { uid: user.uid });

  // batch.commit();
}

// validateUsername() {
//   this.signUpForm.valueChanges.subscribe(debounceTime(500), (v) => {
//     if (v.length >= 3 && v.length <= 15) {
//       const ref = this.afs.doc(`usernames\${v}`);
//       const exists = ref.get();
//       this.usernameAvailable = !exists;
//     }
//   });
// }
