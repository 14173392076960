import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LikeService {
  user: any;
  constructor(private afs: AngularFirestore) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  likePost(postId: string, userId?: string) {
    alert(postId);
    alert(this.user.uid);
    return this.afs.doc(`photos/${postId}`).update({
      likes: firebase.firestore.FieldValue.arrayUnion(this.user.uid),
    });
  }

  unlikePost(postId: string, userId?: string) {
    return this.afs.doc(`photos/${postId}`).update({
      likes: firebase.firestore.FieldValue.arrayRemove(this.user.uid),
    });
  }

  getLikes(postId: string) {
    return this.afs
      .doc(`photos/${postId}`)
      .valueChanges()
      .pipe(map((photo: any) => photo.likes));
  }
}
