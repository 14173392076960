import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/@shared/confirm-dialog/confirm-dialog.component';
import { FireCrudService } from '@app/@shared/services/fire-crud.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { AuthenticationService } from '@app/auth';
import { Upload } from '../interfaces/upload.interface';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  private crudService: FireCrudService<Upload>;

  // AngularFirestore should be found by Angular DI System
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private snack: SnackService
  ) {
    // Let's create our CrusService and use the a Collection with the name 'uploads'
    this.crudService = new FireCrudService<Upload>(afs, 'photos');
  }

  addUpload(title: string, description: string, category: any, photoUrl: string, urlType: string) {
    let user = this.authService.userData;
    let uid = user.uid;
    let email = user.email;
    let username = user.username;
    const _date = new Date();
    const date = _date.getTime().toString();
    return this.crudService
      .add({ uid, email, photoUrl, title, description, category, date, username })
      .then(() => {
        this.snack.open(`Added Photo Successfully!`, '', 2500);
      })
      .catch((err: any) => {
        this.snack.open(`Failed Adding Photo`, '', 2500);
      });
  }

  updateUploadCategory(category: string) {
    // return this.crudService.update({ category });
  }

  deletePhoto(upload: Upload) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          id: upload.id,
          name: upload.title,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.crudService
            .delete(upload.id)
            .then((_res: any) => {
              this.snack.open(`Photo Deleted!`, '', 3000);
            })
            .catch((err: any) => {
              this.snack.open(`Deleting Photo Failed!`, '', 3000);
            });
        } else {
          this.snack.open(`Your Photo is Safe!`, '', 3000);
        }
      });
  }

  getAllPhotos() {
    return this.crudService.list();
  }

  getPhoto(id: string) {
    return this.crudService.get(id);
  }
}
