import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhotoService } from '../services/photo.service';

@Component({
  selector: 'app-upload-details',
  templateUrl: './upload-details.component.html',
  styleUrls: ['./upload-details.component.scss'],
})
export class UploadDetailsComponent implements OnInit {
  upload: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private photoService: PhotoService) {
    console.log('open');
    this.photoService.getPhoto(this.data.id).subscribe((v: any) => {
      console.log(v);
      return (this.upload = v);
    });
  }

  ngOnInit(): void {}
}
