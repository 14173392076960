import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements OnInit {
  usernameText: string;
  usernameAvailable: boolean = true;

  constructor(public auth: AuthenticationService) {}

  ngOnInit() {}

  async checkUsername() {
    setTimeout(async () => {
      if (this.usernameText) {
        (await this.auth.checkUsername(this.usernameText.toLowerCase())).subscribe((username) => {
          username.forEach((a) => {
            Object.keys(a).forEach((u) => {
              if (u === this.usernameText) {
                this.usernameAvailable = false;
                console.log('true');
              } else {
                this.usernameAvailable = true;
                console.log('false');
              }
            });
          });
        });
      }
    }, 1500);
  }

  updateUsername() {
    console.log('USER NAME TEXT', this.usernameText);
    const _usernameText = this.usernameText.toLowerCase();
    this.auth.updateUsername(_usernameText);
  }
}
