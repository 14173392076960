import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  results: any;
  searchTerm$ = new Subject<string>();
  public selectedDate: string;
  constructor(private dialog: MatDialog, private router: Router) {
    let _date = Date.now();
    this.selectedDate = moment(_date).format('MM-DD-YYYY');
  }

  ngOnInit() {}

  goTo(route: string) {
    this.router.navigate([route]);
  }
}
