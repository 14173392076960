<div class="container">
  <div class="cards">
    <mat-card (click)="goTo('home')">
      <mat-card-content>
        <mat-card-title translate class="mat-h1 margin-unset">Home</mat-card-title>
        <img src="/assets/feed.png" alt="Feed Icon" class="card-img" />

        <mat-card-actions>
          <button mat-button>VIEW</button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
