<!-- Main content -->
<main role="main" class="col1 margin1">
  <!-- Show user data when logged in -->
  <div class="col1" *ngIf="user">
    <mat-card class="example-card">
      <mat-card-header>
        <img mat-card-avatar src="{{ user?.photoURL }}" />
        <p>@{{ user?.username }}</p>
      </mat-card-header>
      <mat-card-content class="col1">
        <div class="col2">
          <div class="col1">
            <p>{{ user?.bio || 'No Bio...' }}</p>
          </div>
          <!-- <button mat-flat-button disabled>Followers: {{ followingCount }}</button> -->
          <button mat-flat-button disabled>Followers: {{ followerCount }}</button>
        </div>
      </mat-card-content>

      <mat-card-actions *ngIf="currentUser.uid !== profileId">
        <button mat-stroked-button color="primary" (click)="follow(user.id)" *ngIf="!isFollowing">Follow</button>
        <button mat-stroked-button color="primary" (click)="unfollow(user.id)" *ngIf="isFollowing">Unfollow</button>
      </mat-card-actions>
      <div class="col1">
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Top 5 Friends </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <p>List of top 5 friends.. coming soon.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-card>
    <!-- <img
      class="big-avatar"
      src="{{ user.photoURL ? user.photoURL : '/assets/dummy-user.png' }}"
      alt="{{ user.displayName }}"
    />
    <div class="media-body">
      <div class="col2">
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="displayName" [disabled]="!enable_edit" />
        </mat-form-field>
        <mat-icon matToolTip="You can update your display name only" (click)="enableEdit()">edit</mat-icon>
        <p>
          <button [disabled]="!enable_edit" type="button" mat-stroked-button color="primary" (click)="updateProfile()">
            Update Display Name
          </button>
        </p>
      </div>
      <p class="mat-h2">
        Email: <strong>{{ user.email }}</strong>
      </p>
      <p class="mat-h2">
        Email Verified: <strong>{{ user.emailVerified }}</strong>
      </p>
      <p>
        <button type="button" mat-raised-button color="warn" (click)="deleteProfile()">Delete Profile</button>
      </p>
    </div> -->
  </div>
</main>
<div class="col2 margin1">
  <button mat-stroked-button color="primary" (click)="loadPhotos()"><mat-icon>photo</mat-icon> Photos</button>
  <button mat-stroked-button color="primary" (click)="loadPosts()"><mat-icon>notes</mat-icon>Posts</button>
</div>
<!-- Photos -->
<div class="col1">
  <cdk-virtual-scroll-viewport itemSize="10">
    <ng-container *ngIf="photosLoaded && user">
      <div class="cards">
        <div *ngFor="let photo of userFeed | searchFilter: query">
          <mat-card class="col1 vmargin1">
            <mat-card-header>
              <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="photo?.title" /> -->

              <mat-card-title>{{ parseDisplayEmail(photo?.email) }}</mat-card-title>
              <mat-card-subtitle>{{ fromNow(photo?.date) }}</mat-card-subtitle>
            </mat-card-header>
            <img
              *ngIf="photo?.photoUrl"
              lazyImage
              mat-card-image
              class="mat-elevation-z2"
              [src]="photo?.photoUrl"
              [alt]="photo?.title"
            />

            <mat-card-content class="margin-unset">
              <p>
                {{ photo?.description }}
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(photo, 'photo')">
                <mat-icon>comment</mat-icon>
              </button>
              <!-- <button mat-stroked-button color="warn" type="button" aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
                0
              </button> -->
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div *ngIf="userFeed.length <= 0">
        <span class="mat-h2">
          <p class="center">{{ user.username }} has no photos!</p>
        </span>
      </div>
    </ng-container>
    <!-- End Photos -->

    <!-- Posts -->
    <ng-container *ngIf="postsLoaded && user">
      <div class="cards">
        <div *ngFor="let post of userFeed | searchFilter: query">
          <mat-card class="col1 mat-elevation-z2">
            <mat-card-header>
              <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="post?.title" /> -->

              <mat-card-title>{{ post?.username }}</mat-card-title>
              <mat-card-subtitle>{{ fromNow(post?.date) }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="margin-unset">
              <span class="mat-h2 margin-unset">
                <cite> {{ post?.description }}</cite>
              </span>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(post, 'post')">
                <mat-icon>comment</mat-icon>
              </button>
              <!-- <button mat-stroked-button color="warn" type="button" aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
                2000
              </button> -->
              <!-- <div class="col1 delete-btn vmargin1" *ngIf="post?.uid === post?.uid">
                <button mat-stroked-button (click)="deletePost(post)" color="warn">
                  <mat-icon>delete</mat-icon>Delete
                </button>
              </div> -->
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div *ngIf="userFeed.length <= 0">
        <span class="mat-h2">
          <p class="center">{{ user.username }} has no post!</p>
        </span>
      </div>
    </ng-container>
    <!-- End Posts -->

    <!-- Loader -->
    <ng-container *ngIf="loader">
      <div class="col1 margin2">
        <app-loader [isLoading]="loader" [message]="message" [size]="2"></app-loader>
      </div>
    </ng-container>
    <!-- End Loader -->
  </cdk-virtual-scroll-viewport>
</div>
