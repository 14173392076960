import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Shell } from '@app/shell/shell.service';
import { UploadDetailsComponent } from './upload-details/upload-details.component';
import { UploadComponent } from './upload/upload.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'upload', component: UploadComponent, data: { title: marker('Upload') } },
    { path: 'upload-details', component: UploadDetailsComponent, data: { title: marker('Upload Details') } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class UploadsRoutingModule {}
