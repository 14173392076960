import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './loader/loader.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { SearchFilterPipe } from './search-filter.pipe';
import { SafeUrlPipe } from './safe-url-pipe';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { ParseHtmlPipe } from './pipes/parseHtml/parseHtml.pipe';
import { ScrollableDirective } from './directives/scrollable.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [FlexLayoutModule, MaterialModule, CommonModule, ScrollingModule],
  declarations: [
    LoaderComponent,
    ScrollToTopComponent,
    SearchFilterPipe,
    SafeUrlPipe,
    ParseHtmlPipe,
    InfiniteScrollComponent,
    SpeedDialFabComponent,
    ScrollableDirective,
    ConfirmDialogComponent,
  ],
  exports: [LoaderComponent, ScrollToTopComponent, SearchFilterPipe, SafeUrlPipe, ParseHtmlPipe, SpeedDialFabComponent],
  providers: [DecimalPipe, SearchFilterPipe, SafeUrlPipe, ParseHtmlPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
