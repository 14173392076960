import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FireCrudService } from '@app/@shared/services/fire-crud.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { AuthenticationService } from '@app/auth';
import { Upload } from '../interfaces/upload.interface';

@Injectable({
  providedIn: 'root',
})
export class PostUploadService implements OnInit {
  private crudService: FireCrudService<Upload>;

  // AngularFirestore should be found by Angular DI System
  constructor(private afs: AngularFirestore, private authService: AuthenticationService, private snack: SnackService) {
    // Let's create our CrusService and use the a Collection with the name 'uploads'
    this.crudService = new FireCrudService<Upload>(afs, 'posts');
  }

  ngOnInit() {}

  addPosts(posts: any) {
    let user = this.authService.userData;
    let uid = user.uid;
    let email = user.email;
    let username = user.username;
    const _date = new Date();
    const date = _date.getTime().toString();
    return this.crudService
      .add({ ...posts, date, email, uid, username })
      .then(() => {
        this.snack.open('Added Posts!', '', 2000);
      })
      .catch((err: any) => {
        this.snack.open(`${err}`, '', 2000);
      });
  }

  updatePostCategory(category: string) {
    // return this.crudService.update({ category });
  }

  deletePost(upload: Upload) {
    return this.crudService
      .delete(upload.id)
      .then(() => {
        this.snack.open('Deleted Posts!', '', 2000);
      })
      .catch((err: any) => {
        this.snack.open(`${err}`, '', 2000);
      });
  }

  getAllPosts() {
    return this.crudService.list();
  }

  getPost(id: string) {
    return this.crudService.get(id);
  }
}
