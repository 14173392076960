import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/@shared/confirm-dialog/confirm-dialog.component';
import { FireCrudService } from '@app/@shared/services/fire-crud.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { AuthenticationService } from '@app/auth';
import { Upload } from '../interfaces/upload.interface';

@Injectable({
  providedIn: 'root',
})
export class VideoUploadService {
  private crudService: FireCrudService<Upload>;

  // AngularFirestore should be found by Angular DI System
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private snack: SnackService,
    private dialog: MatDialog
  ) {
    // Let's create our CrusService and use the a Collection with the name 'uploads'
    this.crudService = new FireCrudService<Upload>(afs, 'videos');
  }

  addVideo(title: string, description: string, category: any, videoUrl: string) {
    let user = this.authService.userData;
    let uid = user.uid;
    let email = user.email;
    let username = user.username;
    const _date = new Date();
    const date = _date.getTime().toString();
    return this.crudService
      .add({ uid, email, videoUrl, title, description, category, date, username })
      .then(() => {
        this.snack.open('Added Video!', '', 2500);
      })
      .catch((err: any) => {
        this.snack.open('Failed Uploading Video!', '', 2500);
        console.log(err);
      });
  }

  updateVideoCategory(category: string) {
    // return this.crudService.update({ category });
  }

  deleteVideo(upload: any) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          id: upload.id,
          name: upload.title,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.crudService
            .delete(upload.id)
            .then((_res: any) => {
              this.snack.open(`Video Deleted!`, '', 3000);
            })
            .catch((err: any) => {
              this.snack.open(`Deleting Video Failed!`, '', 3000);
            });
        } else {
          this.snack.open(`Your Video is Safe!`, '', 3000);
        }
      });
  }

  getAllVideos() {
    return this.crudService.list();
  }

  getVideo(id: string) {
    return this.crudService.get(id);
  }
}
