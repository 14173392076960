import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseHtml',
})
export class ParseHtmlPipe implements PipeTransform {
  constructor() {}
  transform(desc: any): string {
    if (desc) {
      return desc.replace(/(<([^>]+)>)/gi, '');
    } else {
      return;
    }
  }
}
