import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@core';
import { AuthenticationService } from '../authentication.service';
import { SnackService } from '@app/@shared/services/snack.service';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snack: SnackService,
    public authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

  async signIn() {
    const { email, password } = this.loginForm.value;
    this.isLoading = true;
    console.log(email, password);
    return await this.authenticationService
      .SignIn(email, password)
      .then((res) => {
        if (!!res) {
          console.log(res, 'res');
          log.debug(`successfully logged in`);
          this.loginForm.markAsPristine();
          this.isLoading = false;
        } else {
          this.loginForm.markAsPristine();
          this.isLoading = false;
          console.log(res, 'res');
          log.debug(`failed logged in`);
        }
      })
      .catch((e: any) => {
        this.isLoading = false;
        log.debug(`Login error: ${e}`);
        this.error = e;
      });
  }

  forgotPassword() {
    this.router.navigateByUrl('forgot-password');
  }
}
