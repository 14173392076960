import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';

import { SignupComponent } from './signup/signup.component';
import { UsernameComponent } from './username/username.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: marker('Login') } },
  { path: 'sign-up', component: SignupComponent, data: { title: marker('Sign Up') } },
  { path: 'username', component: UsernameComponent, data: { title: marker('Create Username') } },
  { path: 'verify-email-address', component: VerifyEmailComponent, data: { title: marker('Verify Email') } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: marker('Forgot Password') } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
