import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from '@app/@shared/interfaces/user';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PostUploadService } from '@app/uploads/services/post-upload.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { VideoUploadService } from '@app/uploads/services/video-upload.service';
import { HostListener } from '@angular/core';
import { PhotoService } from '@app/uploads/services/photo.service';
import { LikeService } from '@app/heart/like.service';
import { map, take } from 'rxjs/operators';
import { CommentsComponent } from './comments/comments.component';
import { ExploreService } from './explore.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  photos: any = [];
  videos: any = [];
  posts: any = [];
  filterContainer: any = [];
  user: User;
  loader = false;
  message = 'Loading Feed';
  query: any;
  photosLoaded: boolean = true;
  videosLoaded: boolean = false;
  postsLoaded: boolean = false;
  videoId: any;
  videosMuted: boolean = true;
  videoPlaying: boolean = false;
  likes = 0;
  liked = false;
  public searchFilter: any = '';

  constructor(
    private exploreService: ExploreService,
    private postsService: PostUploadService,
    private videoService: VideoUploadService,
    private photoService: PhotoService,
    private auth: AngularFireAuth,
    private _bottomSheet: MatBottomSheet,
    private snack: SnackService,
    public likeService: LikeService
  ) {
    this.loader = true;
    this.loadPhotos();
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  openBottomSheet(upload: any, type: any): void {
    this._bottomSheet.open(CommentsComponent, {
      data: {
        type,
        upload,
      },
    });
  }

  // canEdit(arr: any) {
  //   arr.forEach((element: any) => {
  //     let a = moment.valueOf();
  //     let _a = moment(a);
  //     let b = moment(element.date);
  //     let diff = _a.diff(b);
  //     console.log(diff);

  //     arr.sort((a: any, b: any) => {
  //       return b.date - a.date;
  //     });
  //     console.log(arr);

  //     return (this.uploads = arr);
  //   });
  // }

  loadPhotos() {
    // this.auth.authState.subscribe((u: any) => {
    // this.user = u;
    this.photoService.getAllPhotos().subscribe((photoArray: any[]) => {
      console.log(photoArray);
      // photoArray.map(p => {
      //   console.log(this.user);
      //   this.likeService.getLikes(p.id).subscribe(likes => {
      //     let user_liked = likes?.includes(this.user?.uid);
      //     console.log(likes)
      //     p.liked = user_liked;
      //     p.likes = likes?.length || 0;

      //     // photoArray.forEach((p: any) => {
      //     //   console.log('likes... ' + likes)
      //     //   p.likes = likes?.length || 0;
      //     // });
      //   })
      // });
      photoArray.sort((a: any, b: any) => {
        return b.date - a.date;
      });
      // photoArray.filter((p: any) => {
      //   this.likeService.getLikes(p.id).subscribe((likes: any) => {
      //     this.likes = likes?.length || 0;
      //     this.liked = likes?.includes(this.user.id) || false;
      //   });
      // })

      this.loader = false;
      return (this.photos = photoArray), (this.filterContainer = photoArray);
    });
    // });
  }

  loadPosts() {
    this.auth.authState.subscribe((u: any) => {
      this.user = u;
      this.postsService.getAllPosts().subscribe((postsArray) => {
        console.log(postsArray);
        postsArray.sort((a: any, b: any) => {
          return b.date - a.date;
        });
        this.loader = false;
        return (this.posts = postsArray), (this.filterContainer = postsArray);
      });
    });
  }

  filterPhotos(category: any) {
    switch (category) {
      case 'all': {
        this.photos = this.filterContainer;
        break;
      }
      case 'fitness': {
        //statements;

        this.photos = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });
        console.log(this.photos);
        break;
      }
      case 'food': {
        //statements;
        this.photos = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });
        console.log(this.photos);
        break;
      }
      case 'social': {
        console.log(category);
        //statements;
        this.photos = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });
        console.log(this.photos);

        break;
      }
      default: {
        this.photos = this.filterContainer;
        console.log(this.photos);
        break;
      }
    }
  }

  filterPosts(category: any) {
    switch (category) {
      case 'all': {
        console.log(this.filterContainer);
        this.posts = this.filterContainer;
        break;
      }
      case 'fitness': {
        //statements;
        console.log(this.filterContainer);
        this.posts = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });

        break;
      }
      case 'food': {
        //statements;
        console.log(category);
        this.posts = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });

        break;
      }
      case 'social': {
        console.log(category);
        //statements;
        this.posts = this.filterContainer.filter((u: any) => {
          if (u.category === category) return [u];
        });

        break;
      }
      default: {
        this.posts = this.filterContainer;
        break;
      }
    }
  }

  deletePhoto(photo: any) {
    this.photoService.deletePhoto(photo);
  }

  deletePost(post: any) {
    this.postsService.deletePost(post);
  }

  like(postId: any) {
    this.likeService
      .likePost(postId, this.user.id)
      .then(() => {
        this.liked = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  unlike(postId: any) {
    this.likeService
      .unlikePost(postId, this.user.id)
      .then(() => {
        this.liked = false;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fromNow(_d: any): string {
    _d = Number(_d);
    const parsedDate = moment(_d).format('DD MMM YYYY hh:mm a');
    return moment(parsedDate).fromNow();
  }

  parseDisplayEmail(email: any): string {
    return email.split('@')[0];
  }

  loadUpload(type: string) {
    console.log(type);
    switch (type) {
      case 'photos': {
        this.loadPhotos();
        this.photosLoaded = true;
        this.videosLoaded = false;
        this.postsLoaded = false;

        break;
      }

      case 'posts': {
        this.loadPosts();
        this.postsLoaded = true;
        this.photosLoaded = false;
        this.videosLoaded = false;
      }
      default: {
        console.log('No Uploads');
      }
    }
  }
}
