<div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="onToggleFab()"></div>

<div class="fab-container" color="accent">
  <button mat-fab class="fab-toggler" (click)="onToggleFab()" color="primary">
    <i class="material-icons" [@fabToggler]="{ value: fabTogglerState }">menu</i>
  </button>
  <div [@speedDialStagger]="buttons.length">
    <button
      *ngFor="let btn of buttons"
      mat-raised-button
      class="fab-secondary"
      color="accent"
      (click)="navigateTo(btn?.route, btn?.name)"
    >
      {{ btn?.name }} <img [src]="btn?.imgSrc" [alt]="btn?.name" class="icon-img" />
    </button>
    <!-- <app-scroll-to-top></app-scroll-to-top> -->

    <!-- <button
      *ngFor="let btn of buttons"
      mat-mini-fab
      class="fab-secondary"
      color="secondary"
      (click)="navigateTo(btn?.route)"
    >
      <mat-icon class="material-icons">{{ btn.icon }}</mat-icon>
    </button> -->
  </div>
</div>
