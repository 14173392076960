import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FireCrudService } from '@app/@shared/services/fire-crud.service';
import { AuthenticationService } from '@app/auth';
import { Upload } from '@app/uploads/interfaces/upload.interface';
@Injectable({
  providedIn: 'root',
})
export class ExploreService {
  private crudService: FireCrudService<Upload>;
  constructor(private afs: AngularFirestore, private authService: AuthenticationService) {
    this.crudService = new FireCrudService<Upload>(afs, 'uploads');
  }

  getAllUploads() {
    return this.crudService.list();
  }

  deleteUpload(id: string) {
    return this.crudService
      .delete(id)
      .then((res: any) => alert('Deleted'))
      .catch((err: any) => alert('Error Deleting'));
  }
}
