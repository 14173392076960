import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { __await } from 'tslib';
import { User } from '@app/@shared/interfaces/user';
import { SnackService } from '@app/@shared/services/snack.service';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  userData: any; // Save logged in user data
  usernameAvailable: boolean = false;
  redirectUrl!: string;

  private profileObs$: BehaviorSubject<User> = new BehaviorSubject(null);

  getProfileObs(): Observable<User> {
    return this.profileObs$.asObservable();
  }

  setProfileObs(profile: User) {
    this.profileObs$.next(profile);
  }
  user: User;
  destroy$: Subject<null> = new Subject();
  constructor(
    private snack: SnackService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone
  ) {
    // NgZone service to remove outside scope warning) {
    /* Saving user data in localstorage when
    logged in and setting up null when logge
      userData: any; // Save logged in user datad out */
    // if (JSON.parse(localStorage.getItem('user'))) {
    //   this.userData = JSON.parse(localStorage.getItem('user'));
    // } else {
    this.redirectUrl = environment.production
      ? 'https://friendfives-ca992.firebaseapp.com/login'
      : 'http://localhost:4200/login';

    this.afAuth.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.afs
          .collection(`users`)
          .doc(`${user.uid}`)
          .valueChanges()
          .subscribe((u: any) => {
            console.log(u);
            // this.userData = u;
            localStorage.setItem('user', JSON.stringify(u));
            this.setProfileObs(u);
            // JSON.parse(localStorage.getItem('user'));
            return (this.userData = u);
          });
      } else {
        localStorage.setItem('user', null);
        // JSON.parse(localStorage.getItem('user'));
      }
    });
    // }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

  // Sign in with email/password
  async SignIn(email: any, password: any) {
    // try {
    const result = await this.afAuth.signInWithEmailAndPassword(email, password);
    if (result.user) {
      this.afs
        .collection(`users`)
        .doc(`${result.user.uid}`)
        .valueChanges()
        .pipe(take(1))
        .subscribe((u: any) => {
          console.log(u);
          this.userData = u;
          const res = { ...u, emailVerified: result.user?.emailVerified };
          console.log(res, 'USER RES IN SIGNIN');
          this.userData = res;
          localStorage.setItem('user', JSON.stringify(u));
          this.setProfileObs(u);
          JSON.parse(localStorage.getItem('user'));
          this.SetUserData(result.user);
          this.ngZone.run(() => {
            this.router.navigate(['/feed']);
          });
          return (this.userData = u);
        });
    }

    return of(result.user);
    // } catch (error) {
    // this.snack.open(`${error.message}`, '', 3000);
    // }
  }

  // Sign up with email/password
  async SignUp(email: any, password: any) {
    return await this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result: { user: any }) => {
        /* Call the SendVerificaitonMail() function when new user sign
          up and returns promise */

        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error: { message: any }) => {
        // window.alert(error.message);
        this.snack.open(`${error.message}`, 'Dismiss', 3000);
      });
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail(email?: string) {
    {
      return this.afAuth.currentUser
        .then((u: any) => {
          u.sendEmailVerification({
            url: `${this.redirectUrl}`,
          });
        })
        .then((res: any) => {
          console.log(res);
          console.log('called verify email');
          let emailMsg = email ? `Email sent to, ${email}` : `Email sent!`;
          this.snack.open(`${emailMsg}`, 'bottom', 2000);
          this.router.navigate(['verify-email-address']);
        })
        .catch((e: any) => {
          this.snack.open(`${e}`, '', 2500);
        });
    }
  }
  // Reset Forggot password
  async ForgotPassword(passwordResetEmail: any) {
    return await this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.snack.open('Password reset email sent, check your inbox.', '', 3000);
      })
      .catch((error) => {
        this.snack.open(`${error}`, '', 2500);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!!user && user.emailVerified) {
      return true;
    } else if (!!user && !user.emailVerified) {
      this.router.navigate(['verify-email-address']);
      return true;
    } else {
      return false;
    }
  }

  // Returns true when user is admin
  get isAdmin(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    // const user = this.userData;
    if (!!user && user.isAdmin) {
      return true;
    } else if (!!user && !user.isAdmin) {
      return false;
    }
  }
  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  async AuthLogin(provider: firebase.auth.AuthProvider) {
    return await this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/feed']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.snack.open(`${error}`, '', 2500);
      });
  }

  /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: firebase.User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  async SignOut() {
    return await this.afAuth.signOut().then((res: any) => {
      console.log('signout res', res);
      localStorage.setItem('user', this.userData);
      localStorage.removeItem('user');
      this.profileObs$.next(null);
      this.profileObs$.complete();
      this.snack.open(`Successfully Logged Out!`, '', 2500);
      this.router.navigate(['login']);
    });
  }

  get hasUsername(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.hasOwnProperty('username') || user.username === '' ? true : false;
  }

  async checkUsername(username: string) {
    username = username.toLowerCase();
    if (username) {
      const usernameRef = this.afs.collection('usernames').valueChanges();
      // console.log(usernameRef);
      // const ref = this.afs.collection('usernames').doc(`${username}`).valueChanges();
      // ref.subscribe((r) => console.log(r, 'r from username doc'));
      return usernameRef;
      // .doc(`${username}`)
      // .valueChanges({ idField: 'id' })
    }
  }

  updateUsername(username: string) {
    let data = {};
    data[username] = this.userData.uid;

    this.afs.collection(`users`).doc(`${this.userData.uid}`).update({ username: username });
    // this.afs.collection(`users`).doc(`${this.userData.uid}`).update({ bio: '' });
    this.afs
      .collection(`usernames`)
      .add(data)
      .then(() => {
        this.snack.open('Added username', '', 3000);
        this.router.navigate(['feed']);
      })
      .catch((err) => {
        console.log(err);
        this.snack.open('Failed selecting username', 'Try again!', 3000);
      });
  }
}
