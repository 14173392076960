<!-- Main content -->
<main role="main" class="col1 margin1">
  <!-- Show user data when logged in -->
  <div class="col1" *ngIf="user as user">
    <mat-card class="example-card">
      <mat-card-header>
        <img mat-card-avatar src="{{ userImagePreview ? userImagePreview : user.photoURL }}" />

        <form [formGroup]="pictureForm" class="col1">
          <div>
            <!-- <label for="file-upload" class="custom-file-upload mat-elevation-z2">
              <mat-icon>person</mat-icon>
              <span class="mat-h3 margin-unset"> Upload Avatar</span>
            </label> -->
            <input
              style="display: none"
              id="file-upload"
              type="file"
              #userPhoto
              (change)="onFileSelected($event)"
              name="image"
              autocomplete="off"
              accept="image/*"
            />
          </div>

          <!-- <img *ngIf="userImagePreview" [src]="userImagePreview" alt="User Upload" class="preview-image" />
          <mat-progress-bar *ngIf="submitted" [value]="uploadProgress$ | async" mode="determinate"> </mat-progress-bar>
          <button (click)="postPhoto()" [disabled]="submitted || !userImagePreview" color="primary" mat-raised-button>
            Update Avatar
          </button> -->
          <div class="col1">
            <button mat-icon-button class="edit" (click)="userPhoto.click()" *ngIf="enable_edit">
              <mat-icon>cloud_upload</mat-icon>
            </button>

            <button mat-icon-button (click)="postPhoto()" *ngIf="userImagePreview">
              <mat-icon>save</mat-icon>
            </button>
          </div>

          <!-- <ng-template #avatar>
            <input style="display: none" type="file" accept="image/*" (change)="onFileSelected($event)" #userPhoto />

            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="6 6 36 36">
              <path
                d="M6 10v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4H10c-2.21 0-4 1.79-4 4zm24 8c0 3.32-2.69 6-6 6s-6-2.68-6-6c0-3.31 2.69-6 6-6s6 2.69 6 6zM12 34c0-4 8-6.2 12-6.2S36 30 36 34v2H12v-2z"
              />
            </svg>

            <ng-container *ngIf="uploadProgress$ | async as progress">
              <mat-progress-bar [mode]="progress < 100 ? 'determiante' : 'indeterminate'" [value]="progress">
              </mat-progress-bar>
            </ng-container>

            <button mat-icon-button class="edit" (click)="userPhoto.click()">
              <mat-icon>cloud_upload</mat-icon>
            </button>
          </ng-template> -->
        </form>

        <!-- <mat-card-title>{{ parseDisplayEmail(user.email) }}</mat-card-title> -->
        <mat-card-title>@{{ user.username }}</mat-card-title>
        <mat-card-subtitle>
          Email Verified: <strong>{{ user.emailVerified }}</strong></mat-card-subtitle
        >
      </mat-card-header>
      <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu" /> -->
      <mat-card-content *ngIf="!enable_edit">
        <p>{{ user.bio || 'Fill out Bio...' }}</p>
      </mat-card-content>
      <mat-card-content *ngIf="enable_edit">
        <mat-form-field appearance="fill" class="bio-field">
          <mat-label>Bio</mat-label>
          <textarea type="textarea" matInput [(ngModel)]="bio" maxlength="100"></textarea>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="enableEdit()">
          {{ enableEditBtn ? 'CANCEL' : 'EDIT' }}
        </button>
        <button *ngIf="enable_edit" type="button" mat-flat-button color="primary" (click)="updateProfile()">
          Update Profile
        </button>
      </mat-card-actions>
    </mat-card>
    <!-- <img
      class="big-avatar"
      src="{{ user.photoURL ? user.photoURL : '/assets/dummy-user.png' }}"
      alt="{{ user.displayName }}"
    />
    <div class="media-body">
      <div class="col2">
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="displayName" [disabled]="!enable_edit" />
        </mat-form-field>
        <mat-icon matToolTip="You can update your display name only" (click)="enableEdit()">edit</mat-icon>
        <p>
          <button [disabled]="!enable_edit" type="button" mat-stroked-button color="primary" (click)="updateProfile()">
            Update Display Name
          </button>
        </p>
      </div>
      <p class="mat-h2">
        Email: <strong>{{ user.email }}</strong>
      </p>
      <p class="mat-h2">
        Email Verified: <strong>{{ user.emailVerified }}</strong>
      </p>
      <p>
        <button type="button" mat-raised-button color="warn" (click)="deleteProfile()">Delete Profile</button>
      </p>
    </div> -->
  </div>
</main>
<div class="col2 margin1">
  <button mat-stroked-button color="primary" (click)="loadPhotos()"><mat-icon>photo</mat-icon> Photos</button>
  <button mat-stroked-button color="primary" (click)="loadPosts()"><mat-icon>notes</mat-icon>Posts</button>
</div>
<!-- Photos -->
<div class="col1">
  <cdk-virtual-scroll-viewport itemSize="10">
    <ng-container *ngIf="photosLoaded && user">
      <div class="cards">
        <div *ngFor="let photo of userFeed | searchFilter: query">
          <mat-card class="col1 vmargin1">
            <mat-card-header>
              <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="photo?.title" /> -->

              <mat-card-title>{{ parseDisplayEmail(photo?.email) }}</mat-card-title>
              <mat-card-subtitle>{{ fromNow(photo?.date) }}</mat-card-subtitle>
            </mat-card-header>
            <img
              *ngIf="photo?.photoUrl"
              lazyImage
              mat-card-image
              class="mat-elevation-z2"
              [src]="photo?.photoUrl"
              [alt]="photo?.title"
            />

            <mat-card-content class="margin-unset">
              <p>
                {{ photo?.description }}
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(photo, 'photo')">
                <mat-icon>comment</mat-icon> 0
              </button>
              <button mat-stroked-button color="warn" type="button" aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
                0
              </button>
              <div class="col1 delete-btn vmargin1" *ngIf="photo?.uid === user?.uid">
                <button mat-stroked-button (click)="deleteUpload(photo?.id)" color="warn">
                  <mat-icon>delete</mat-icon>Delete
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div *ngIf="userFeed.length <= 0">
        <span class="mat-h2">
          <p class="center">No Photos. Add one!</p>
        </span>
      </div>
    </ng-container>
    <!-- End Photos -->

    <!-- Posts -->
    <ng-container *ngIf="postsLoaded && user">
      <div class="cards">
        <div *ngFor="let post of userFeed | searchFilter: query">
          <mat-card class="col1 mat-elevation-z2">
            <mat-card-header>
              <!-- <img mat-card-avatar class="example-header-image" src="/assets/ff-logos/3.png" [alt]="post?.title" /> -->

              <mat-card-title>{{ parseDisplayEmail(post?.email) }}</mat-card-title>
              <mat-card-subtitle>{{ fromNow(post?.date) }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="margin-unset">
              <span class="mat-h2 margin-unset">
                <cite> {{ post?.description }}</cite>
              </span>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button color="primary" type="button" (click)="openBottomSheet(post, 'post')">
                <mat-icon>comment</mat-icon> 50
              </button>
              <button mat-stroked-button color="warn" type="button" aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
                2000
              </button>
              <div class="col1 delete-btn vmargin1" *ngIf="post?.uid === post?.uid">
                <button mat-stroked-button (click)="deletePost(post)" color="warn">
                  <mat-icon>delete</mat-icon>Delete
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div *ngIf="userFeed.length <= 0">
        <span class="mat-h2">
          <p class="center">No Posts. Add one!</p>
        </span>
      </div>
    </ng-container>
    <!-- End Posts -->

    <!-- Loader -->
    <ng-container *ngIf="loader">
      <div class="col1 margin2">
        <app-loader [isLoading]="loader" [message]="message" [size]="2"></app-loader>
      </div>
    </ng-container>
    <!-- End Loader -->
  </cdk-virtual-scroll-viewport>
</div>
