import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload/upload.component';
import { UploadDetailsComponent } from './upload-details/upload-details.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { UploadsRoutingModule } from './uploads-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    UploadsRoutingModule,
  ],
  declarations: [UploadComponent, UploadDetailsComponent],
  exports: [UploadComponent, UploadDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploadsModule {}
