<div *ngIf="auth.userData">
  <main class="margin2 col1">
    <span class="mat-h1 center">Choose a Username</span>
    <mat-form-field appearance="outline">
      <input
        type="text"
        class="input"
        matInput
        placeholder="choose a username"
        [(ngModel)]="usernameText"
        (keyup)="checkUsername()"
      />
      <mat-hint class="help is-success" *ngIf="usernameAvailable && usernameText"
        >@{{ usernameText }} is available</mat-hint
      >
      <mat-hint class="help is-danger" *ngIf="!usernameAvailable && usernameText"
        >@{{ usernameText }} has already been taken</mat-hint
      >
    </mat-form-field>

    <button mat-button color="primary" [disabled]="!usernameAvailable || !usernameText" (click)="updateUsername()">
      Select Username
    </button>
  </main>
</div>
