import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileService } from './profile.service';
import * as moment from 'moment';
import { PostUploadService } from '@app/uploads/services/post-upload.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormGroup } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FollowService } from './follow.service';
import { ExploreService } from '@app/home/explore.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsComponent } from '@app/home/comments/comments.component';
import { User } from '@app/@shared/interfaces/user';
export function debounce(delay: number = 300): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args: any) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Output() logoutClick: EventEmitter<null> = new EventEmitter<null>();
  destroy$: Subject<null> = new Subject();
  _user: BehaviorSubject<any> = new BehaviorSubject<any>({});
  unsubscribe$: Subject<boolean> = new Subject();
  user: any;
  userFeed: any = [];
  photos: any = [];
  videos: any = [];
  posts: any = [];
  displayName: any;
  bio: any;
  enable_edit: boolean = false;
  loader = false;
  message = 'Loading Feed';
  query: any;
  photosLoaded: boolean = false;
  videosLoaded: boolean = false;
  postsLoaded: boolean = false;
  public searchFilter: any = '';
  pictureForm: FormGroup;
  fileToUpload: File;
  userImagePreview: string | ArrayBuffer;
  submitted = false;
  uploadProgress$: Observable<number>;
  profileId: any;
  isFollowing = false;
  currentUser: User;
  followingUsers: any = [];
  followerCount: any;
  followingCount: any;
  constructor(
    private exploreService: ExploreService,
    private postsService: PostUploadService,
    private profileService: ProfileService,
    private _bottomSheet: MatBottomSheet,
    private followService: FollowService,
    private _activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));

    this._activatedRoute.params.subscribe((params) => {
      this.profileId = params['id'];
      console.log(this.profileId);
    });

    // this.authService.getProfileObs().subscribe((u) => console.log('Profile obs', (this.user = u)));
    this.profileService.loadUserProfile(this.profileId).subscribe((user: any) => {
      console.log(user);
      this.user = user;
      if (this.user) {
        this.displayName = this.user.displayName;
        this.bio = this.user.bio ? this.user.bio : 'Fill Out Your Bio...';
        this.loadPhotos();
        console.log('before get following');
        this.followService.getFollowing(this.currentUser.uid).subscribe((followingUsers) => {
          console.log(this.followingUsers);
          this.followingUsers = followingUsers;
          // this.followingCount = followingUsers.length || 0;
          this.isFollowing = followingUsers.includes(this.user.id);
        });

        this.followService.getFollowers(this.user.id).subscribe((count) => {
          this.followerCount = count.length || 0;
        });
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }

  follow(user_id: any) {
    console.log('followed');
    this.followService.followUser(this.currentUser.uid, user_id).subscribe(() => {
      this.isFollowing = true;
      (error: any) => {
        console.log(error);
      };
    });
  }

  unfollow(user_id: any) {
    console.log('unfollowed');
    this.followService.unfollowUser(this.currentUser.uid, user_id).subscribe(
      () => {
        this.isFollowing = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.photosLoaded = true;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  fromNow(_d: any): string {
    _d = Number(_d);
    const parsedDate = moment(_d).format('DD MMM YYYY hh:mm a');
    return moment(parsedDate).fromNow();
  }

  parseDisplayEmail(email: any): string {
    return email.split('@')[0];
  }

  loadPhotos() {
    this.loader = true;
    this.postsLoaded = false;
    this.userFeed = [];
    this.exploreService.getAllUploads().subscribe((r: any) => {
      this.photosLoaded = true;
      this.loader = false;
      console.log(r);
      this.userFeed = r.filter((el: any) => el.uid === this.user.uid);
    });
  }

  loadPosts() {
    this.loader = true;
    this.photosLoaded = false;
    this.userFeed = [];
    this.postsService.getAllPosts().subscribe((r) => {
      this.postsLoaded = true;
      console.log(r);
      this.loader = false;
      this.userFeed = r.filter((el) => el.uid === this.user.uid);
    });
  }

  openBottomSheet(upload: any, type: any): void {
    this._bottomSheet.open(CommentsComponent, {
      data: {
        type,
        upload,
      },
    });
  }
  logout() {
    this.logoutClick.emit();
  }
}
