import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, takeUntil } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '@app/@shared/services/storage.service';
import { UtilService } from '@app/@shared/services/util.service';
import { AuthenticationService } from '@app/auth';
import { MEDIA_STORAGE_PATH } from '@app/@shared/const/storage.const';
import { User } from '@app/@shared/interfaces/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { duration } from 'moment';
import { SnackService } from '@app/@shared/services/snack.service';
import { PostUploadService } from '../services/post-upload.service';
import { VideoUploadService } from '../services/video-upload.service';
import { PhotoService } from '../services/photo.service';
import { ImageCompressionService } from '../services/image-compression.service';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject();
  fileToUpload: File;
  userImagePreview: string | ArrayBuffer;
  userVideoPreview: string | ArrayBuffer;
  pictureForm: FormGroup;
  videoForm: FormGroup;
  postsForm: FormGroup;
  submitted = false;
  uploadProgress$: Observable<number>;
  user: User;
  urlType: string;
  photoUpload: boolean = false;
  videoUpload: boolean = false;
  postsUpload: boolean = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly storageService: StorageService,
    private readonly utilService: UtilService,
    private readonly photoService: PhotoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UploadComponent>,
    private posts: PostUploadService,
    private imageCompressService: ImageCompressionService
  ) {}

  ngOnInit(): void {
    this.authService.afAuth.user.pipe(takeUntil(this.destroy$)).subscribe((user: any) => (this.user = user));
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

  initPhotoForm() {
    this.pictureForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      photo: [null, [Validators.required, this.image.bind(this)]],
      description: [null],
      category: [null, [Validators.required]],
    });

    this.pictureForm
      .get('photo')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
      });
  }

  initPostsForm() {
    this.postsForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(222)]],
      category: [null, [Validators.required]],
    });
  }

  handleFileChange([uploadImage]: any) {
    this.fileToUpload = uploadImage;
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      this.userImagePreview = loadEvent.target.result;
    };

    reader.readAsDataURL(uploadImage);
  }

  handleVideoFileChange([uploadVideo]: any) {
    this.fileToUpload = uploadVideo;
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      // if (
      //   loadEvent.target.result
      //     .toString()
      //     .match(/(?:https?|ftp):\/\/[\S]*\.(?:png|jpe?g|gif|svg|webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)
      // ) {
      //   this.urlType = 'image';
      // }
      // else if (
      //   loadEvent.target.result
      //     .toString()
      //     .match(/(?:https?|ftp):\/\/[\S]*\.(?:mp4|ogg|MOV)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)
      // ) {
      //   this.urlType = 'movie';
      // }
      this.userVideoPreview = loadEvent.target.result;
    };

    reader.readAsDataURL(uploadVideo);
  }
  postPhoto() {
    this.submitted = true;
    const mediaFolderPath = `${MEDIA_STORAGE_PATH}/${this.user.email}/photos/`;

    const { downloadUrl$, uploadProgress$ } = this.storageService.uploadFileAndGetMetadata(
      mediaFolderPath,
      this.fileToUpload
    );
    // const { downloadUrl$, uploadProgress$ } = this.imageCompressService.compressAndUploadImage(
    //   mediaFolderPath,
    //   this.fileToUpload,
    // );

    this.uploadProgress$ = uploadProgress$;
    downloadUrl$
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.snackBar.open(`${error.message} 😢`, 'Close', {
            duration: 4000,
          });
          return EMPTY;
        })
      )
      .subscribe((downloadUrl) => {
        console.log(downloadUrl);
        // parseUrl(url: string) {
        // if (downloadUrl.match(/(?:https?|ftp):\/\/[\S]*\.(?:png|jpe?g|gif|svg|webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)) {
        //   this.urlType = 'image';
        // } else if (downloadUrl.match(/(?:https?|ftp):\/\/[\S]*\.(?:mp4|ogg|MOV)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)) {
        //   this.urlType = 'movie';
        // }
        // }

        const picForm = this.pictureForm.value;
        this.photoService.addUpload(picForm.title, picForm.description, picForm.category, downloadUrl, this.urlType);
        this.submitted = false;
        this.router.navigate([`/feed`]);
        this.dialogRef.close();
      });
  }

  onFileSelected(event: any) {
    this.handleFileChange([event.target.files[0]]);
    this.pictureForm.value.photo = event.target.files[0];
    this.pictureForm.valid;
    this.pictureForm.patchValue({
      photo: [null, [Validators.required, this.image.bind(this)]],
    });

    this.pictureForm
      .get('photo')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
      });
    console.log(this.pictureForm.value);
  }

  private image(photoControl: AbstractControl): { [key: string]: boolean } | null {
    if (photoControl.value) {
      const [uploadImage] = photoControl.value.files;
      return this.utilService.validateFile(uploadImage)
        ? null
        : {
            image: true,
          };
    }
    return;
  }

  uploadPost() {
    const postsForm = this.postsForm.value;
    this.posts.addPosts(postsForm);
  }

  switchUploadType(type: string) {
    console.log(type);
    switch (type) {
      case 'photo': {
        this.photoUpload = true;
        this.videoUpload = false;
        this.postsUpload = false;
        this.initPhotoForm();
        break;
      }

      case 'post': {
        this.initPostsForm();
        this.postsUpload = true;
        this.photoUpload = false;
        this.videoUpload = false;
        break;
      }
      default: {
        break;
      }
    }
  }
}
