<div
  fxFlex
  [class.dark-theme]="hasToggledTheme"
  [ngStyle]="{ 'background-color': hasToggledTheme ? '#111111' : '#ffffff' }"
>
  <!-- <mat-sidenav-container fxFill> -->
  <!-- <mat-sidenav
      #sidenav
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="!isMobile"
      [disableClose]="!isMobile"
      ngClass.gt-sm="has-border"
    > -->
  <mat-toolbar color="primary">
    <img class="example-header-image" src="assets/ff-logos/3.png" alt="APP_NAME" routerLink="/home" />
    <div fxFlex></div>

    <div>
      <button mat-flat-button color="primary" routerLink="home"><mat-icon>home</mat-icon></button>
      <button mat-flat-button color="primary" (click)="upload()"><mat-icon>cloud_upload</mat-icon></button>
      <button mat-icon-button (click)="toggleTheme()">
        <mat-icon class="mr-8" *ngIf="!hasToggledTheme">brightness_5</mat-icon>
        <mat-icon *ngIf="hasToggledTheme">bedtime</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="userMenu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu">
        <mat-list *ngIf="authenticationService.userData as user">
          <mat-list-item>
            <b>{{ user.email }}</b>
          </mat-list-item>
          <mat-list-item routerLink="/edit-profile">
            <b>Edit Profile</b>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item [routerLink]="['/profile', user.uid]">
            <b>Public Profile</b>
          </mat-list-item>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon><b>Logout</b></button>
        </mat-list>
      </mat-menu>
    </div>
    <!-- <app-language-selector icon="true"></app-language-selector> -->
  </mat-toolbar>
  <!-- <nav>
        <mat-list (click)="passIcon()">
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate> <img src="/assets/home.png" alt="Home Icon" class="icon-img" /> Home</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/about" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate><img src="/assets/info.png" alt="Info Icon" class="icon-img" /> About</span>
          </a>
          <mat-divider></mat-divider>

          <a mat-list-item routerLink="/feed" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate> <img src="/assets/feed.png" alt="Feed Icon" class="icon-img" /> Feed</span>
          </a>
          <mat-divider></mat-divider>

          <a mat-list-item routerLink="/workout-plans" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate>
              <img src="/assets/workout-plans.png" alt="Workout Plans Icon" class="icon-img" /> Workout Plans
            </span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/workouts" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate> <img src="/assets/workouts.png" alt="Workouts Icon" class="icon-img" /> Workouts</span>
          </a>
          <mat-divider></mat-divider>

          <a mat-list-item routerLink="/mealplans" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate>
              <img src="/assets/meal-plans.png" alt="Meal Plans Icon" class="icon-img" /> Meal Plans</span
            >
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/recipes" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate> <img src="/assets/recipes.png" alt="Recipes Icon" class="icon-img" /> Recipes</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/shopping-list" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate>
              <img src="/assets/shopping-list.png" alt="Shopping List Icon" class="icon-img" /> Shopping List</span
            >
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item (click)="calorieTracker()" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate>
              <img src="/assets/calorie-tracker.png" alt="Calorie Tracker Icon" class="icon-img" /> Calorie
              Tracker</span
            >
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/ebooks" routerLinkActive="active" (click)="isMobile && sidenav.close()">
            <span translate> <img src="/assets/ebooks.png" alt="Ebooks Icon" class="icon-img" /> Ebooks</span>
          </a>
          <mat-divider></mat-divider>
        </mat-list>
      </nav> -->
  <!-- </mat-sidenav> -->

  <!-- <mat-sidenav-content> -->
  <!-- <mat-toolbar color="primary">
    <div>
      <button class="menu-button" mat-icon-button (click)="sidenav.toggle()" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
          </button>
      <span style="vertical-align: middle">{{ title }}</span>
    </div>
     <div>
          <button mat-flat-button color="primary" (click)="upload()">
            <mat-icon>cloud_upload</mat-icon>&nbsp;Upload
          </button>
          <button mat-icon-button [matMenuTriggerFor]="userToolBarMenu">
            <mat-icon>person</mat-icon>
          </button>
          <mat-menu #userToolBarMenu="matMenu">
            <mat-list *ngIf="authenticationService.userData as user">
              <mat-list-item routerLink="/profile">
                <b>{{ user.email }}</b>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item routerLink="/profile">
                <b>My Profile</b>
              </mat-list-item>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon> <b>Logout</b></button>
            </mat-list>
          </mat-menu>
        </div>
  </mat-toolbar>-->

  <mat-toolbar color="primary" class="secondToolbar" [ngClass]="{ 'mat-elevation-z5': true, sticky: isSticky }">
    <div>
      <!-- <button class="menu-button" mat-icon-button (click)="sidenav.toggle()" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
          </button> -->

      <span
        >{{ title }}
        <!-- <img [src]="icon_url" [alt]="title" class="icon-img" /> -->
      </span>
    </div>
    <div>
      <button mat-flat-button color="primary" (click)="upload()"><mat-icon>cloud_upload</mat-icon>&nbsp; Upload</button>
      <button mat-icon-button (click)="toggleTheme()">
        <mat-icon class="mr-8" *ngIf="!hasToggledTheme">brightness_5</mat-icon>
        <mat-icon *ngIf="hasToggledTheme">bedtime</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="userToolBarMenu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #userToolBarMenu="matMenu">
        <mat-list *ngIf="authenticationService.userData as user">
          <mat-list-item routerLink="/profile">
            <b>{{ user.email }}</b>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item routerLink="/profile">
            <b>My Profile</b>
          </mat-list-item>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon> <b>Logout</b></button>
        </mat-list>
      </mat-menu>
    </div>
  </mat-toolbar>

  <!-- <button class="mine" mat-fab color="primary" (click)="upload()">
        <mat-icon>check</mat-icon>
      </button> -->

  <router-outlet></router-outlet>

  <!-- </mat-sidenav-content> -->
  <!-- </mat-sidenav-container> -->
</div>
