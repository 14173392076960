<div class="col1 hmargin1">
  <mat-card class="m-auto">
    <div class="col1">
      <h3>Thank You for Registering</h3>

      <div *ngIf="authService.userData as user">
        <p class="center">
          We have sent a confirmation email to <strong>{{ user.email }}</strong
          >.
        </p>
        <p class="center">Please check your email and click on the link to verfiy your email address.</p>
      </div>

      <!-- Calling SendVerificationMail() method using authService Api -->
      <div class="col1">
        <button type="button" mat-raised-button color="primary" (click)="authService.SendVerificationMail()">
          <mat-icon>redo</mat-icon>
          Resend Verification Email
        </button>
      </div>
    </div>

    <div class="col1 vmargin2">
      <span>Go back to?<span class="redirect" routerLink="/login"> Login</span></span>
    </div>
  </mat-card>
</div>
