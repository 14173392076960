import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Platform } from '@angular/cdk/platform';
import { PwaPromptComponent } from '../pwa-prompt/pwa-prompt.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private promptEvent: any;

  constructor(private bottomSheet: MatBottomSheet, private platform: Platform) {}

  initPwaPrompt() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // do things here
      // set a variable to be used when calling something
      // e.g. call Google Analytics to track standalone use
      console.log('Already installed PWA');
    } else {
      if (this.platform.ANDROID) {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.promptEvent = event;
          this.openPromptComponent('android');
        });
      }
      if (this.platform.IOS) {
        const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
        if (!isInStandaloneMode) {
          this.openPromptComponent('ios');
        }
      }
    }
  }

  openPromptComponent(mobileType: 'ios' | 'android') {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => {
        this.bottomSheet.open(PwaPromptComponent, { data: { mobileType, promptEvent: this.promptEvent } });
      });
  }
}
