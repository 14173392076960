import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressionService {
  constructor(private storage: AngularFireStorage, private firestore: AngularFirestore) {}

  // compressAndUploadImage(file: File, path: string, quality: number): Observable<any> {
  //   // Compress the image
  //   const compressedImage = this.compressImage(file, quality);

  //   // Upload the compressed image to Firebase storage
  //   const task = this.storage.upload(path, compressedImage);

  //   // Observe the task's progress and return the download URL
  //   return task.snapshotChanges().pipe(
  //     map(() => {
  //       return this.storage.ref(path).getDownloadURL();
  //     })
  //   );
  // }

  compressImage(file: File, quality: number): File {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Create a new image with the compressed data
    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        const byteString = atob(compressedDataUrl.split(',')[1]);
        const mimeString = compressedDataUrl.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        file = new File([ab], file.name, { type: mimeString });
      };
    };
    return file;
  }
}
