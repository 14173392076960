<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
  <button
    color="primary"
    type="button"
    mat-icon-button
    data-toggle="button"
    aria-pressed="true"
    (click)="scrollToTop()"
  >
    <mat-icon>north</mat-icon>
  </button>
</div>
