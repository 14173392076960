import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: [speedDialFabAnimations],
})
export class SpeedDialFabComponent implements OnInit {
  @Input() options: any;
  public selectedDate = new Date().setHours(0, 0, 0, 0);
  buttons: any = [];
  fabTogglerState = 'inactive';
  icon_url: string;
  constructor(private router: Router, private titleService: Title, private dialog: MatDialog) {}

  ngOnInit() {
    const maxButtons = 11;
    if (this.options.buttons.length > maxButtons) {
      this.options.buttons.splice(5, this.options.buttons.length - maxButtons);
    }
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.options.buttons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  navigateTo(route: string, name: string) {
    this.router.navigateByUrl(route);
    this.titleService.setTitle(name);
    this.parseIcon(name);
    this.hideItems();
  }

  parseIcon(string: any) {
    console.log(string);
    switch (string) {
      case 'Feed': {
        this.icon_url = '/assets/feed.png';
        break;
      }
      case 'Workout Plans': {
        this.icon_url = '/assets/workout-plans.png';
        break;
      }
      case 'Workouts': {
        this.icon_url = '/assets/workouts.png';
        break;
      }
      case 'Meal Plans': {
        this.icon_url = '/assets/meal-plans.png';
        break;
      }
      case 'Recipes': {
        this.icon_url = '/assets/recipes.png';
        break;
      }
      case 'Shopping List': {
        this.icon_url = '/assets/shopping-list.png';
        break;
      }
      case 'Calorie Tracker': {
        this.icon_url = '/assets/calorie-tracker.png';
        break;
      }
      case 'Ebooks': {
        this.icon_url = '/assets/ebooks.png';
        break;
      }
      case 'Home': {
        this.icon_url = '/assets/home.png';
        break;
      }
      default: {
        this.icon_url = '/assets/ff-logos/3.png';
        break;
      }
    }
  }

  get title(): string {
    return this.titleService.getTitle();
  }
}
