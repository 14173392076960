import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/@shared/confirm-dialog/confirm-dialog.component';
import { SnackService } from '@app/@shared/services/snack.service';
import * as moment from 'moment';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  photoCommentsRef: any;
  videoCommentsRef: any;
  postCommentsRef: any;
  comment: any = {};
  comments: any = [];
  user: any;
  loader = false;
  message = 'Loading Comments';
  uploadType: any;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<CommentsComponent>,
    private afs: AngularFirestore,
    private snack: SnackService,
    private dialog: MatDialog
  ) {
    console.log(this.data);
    this.photoCommentsRef = this.afs.collection('photos').doc(`${this.data.upload.id}`).collection('comments');
    this.videoCommentsRef = this.afs.collection('videos').doc(`${this.data.upload.id}`).collection('comments');
    this.postCommentsRef = this.afs.collection('posts').doc(`${this.data.upload.id}`).collection('comments');
    this.user = JSON.parse(localStorage.getItem('user'));
    this.loader = true;
  }

  ngOnInit(): void {
    this.loadComments();
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  loadComments() {
    console.log(this.data);
    if (this.data.type === 'photo') {
      this.uploadType = 'photo';
      return this.photoCommentsRef.valueChanges({ idField: 'id' }).subscribe((comments: any) => {
        const sortedCommments = comments.sort((a: any, b: any) => {
          return b.date_created - a.date_created;
        });
        this.render(sortedCommments);
      });
    }
    if (this.data.type === 'video') {
      this.uploadType = 'video';
      return this.videoCommentsRef.valueChanges({ idField: 'id' }).subscribe((comments: any) => {
        const sortedCommments = comments.sort((a: any, b: any) => {
          return b.date_created - a.date_created;
        });
        this.render(sortedCommments);
      });
    }
    if (this.data.type === 'post') {
      this.uploadType = 'post';
      return this.postCommentsRef.valueChanges({ idField: 'id' }).subscribe((comments: any) => {
        const sortedCommments = comments.sort((a: any, b: any) => {
          return b.date_created - a.date_created;
        });
        this.render(sortedCommments);
      });
    }
  }

  createComment() {
    if (this.uploadType === 'photo') {
      if (this.comment.text) {
        const _date = new Date();
        const _date_created = _date.getTime().toString();
        const newComment = {
          text: this.comment.text,
          uid: this.user.uid,
          username: this.user.username,
          date_created: _date_created,
        };
        this.photoCommentsRef.add(newComment);
        this.comment.text = '';
      } else {
        this.snack.open('No Text', 'Say something nice!', 2500);
      }
    }
    if (this.uploadType === 'video') {
      if (this.comment.text) {
        const _date = new Date();
        const _date_created = _date.getTime().toString();
        const newComment = {
          text: this.comment.text,
          uid: this.user.uid,
          username: this.user.username,
          date_created: _date_created,
        };
        this.videoCommentsRef.add(newComment);
        this.comment.text = '';
      } else {
        this.snack.open('No Text', 'Say something nice!', 2500);
      }
    }
    if (this.uploadType === 'post') {
      if (this.comment.text) {
        const _date = new Date();
        const _date_created = _date.getTime().toString();
        const newComment = {
          text: this.comment.text,
          uid: this.user.uid,
          username: this.user.username,
          date_created: _date_created,
        };
        this.postCommentsRef.add(newComment);
        this.comment.text = '';
      } else {
        this.snack.open('No Text', 'Say something nice!', 2500);
      }
    }
  }

  deleteComment(id: any) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          id: id,
          // name: name,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        console.log(res);
        if (res) {
          if (this.uploadType === 'photo') {
            return this.photoCommentsRef
              .doc(`${id}`)
              .delete()
              .then(() => {
                this.snack.open('Your comment was deleted.', 'Dismiss', 5000);
              })
              .catch((err: any) => console.log(err));
          }
          if (this.uploadType === 'video') {
            return this.videoCommentsRef
              .doc(`${id}`)
              .delete()
              .then(() => {
                this.snack.open('Your comment was deleted.', 'Dismiss', 5000);
              })
              .catch((err: any) => console.log(err));
          }
          if (this.uploadType === 'post') {
            return this.postCommentsRef
              .doc(`${id}`)
              .delete()
              .then(() => {
                this.snack.open('Your comment was deleted.', 'Dismiss', 5000);
              })
              .catch((err: any) => console.log(err));
          }
        } else {
          this.snack.open(`Your Comment is Safe!`, '', 3000);
        }
      });
  }

  render(comments: any) {
    this.loader = false;
    return (this.comments = comments);
  }

  fromNow(_d: any): string {
    _d = Number(_d);
    const parsedDate = moment(_d).format('DD MMM YYYY hh:mm a');
    return moment(parsedDate).fromNow();
  }
}
