import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from '@app/@core';
import { AuthenticationService } from '../authentication.service';
const log = new Logger('Forgot Password');

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  error: string | undefined;
  forgotPW!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public auth: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  sendEmail() {
    const { email } = this.forgotPW.value;
    this.isLoading = true;
    console.log(email);
    const signup$ = this.auth.ForgotPassword(email);
    signup$
      .then((res: any) => {
        this.forgotPW.markAsPristine();
        this.isLoading = false;
        console.log(res, 'res');
        log.debug(`successfully signed up`);
      })
      .catch((e: any) => {
        log.debug(`Login error: ${e}`);
        this.error = e;
      });
    // .finally(() => {});
  }

  private createForm() {
    this.forgotPW = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  login() {
    this.router.navigate(['login']);
  }
}
