<div class="col1">
  <span class="margin-unset mat-h1">Select Upload Type</span>
  <div class="col1 margin-unset">
    <button mat-stroked-button color="primary" (click)="switchUploadType('photo')" [disabled]="photoUpload">
      <mat-icon>photo</mat-icon>Photo
    </button>
    <!-- <button mat-stroked-button color="primary" (click)="switchUploadType('video')" [disabled]="videoUpload">
      <mat-icon>videocamera</mat-icon>Video
    </button> -->
    <button mat-stroked-button color="primary" (click)="switchUploadType('post')" [disabled]="postsUpload">
      <mat-icon>notes</mat-icon>Post
    </button>
  </div>
</div>
<div class="col1 vmargin1" *ngIf="photoUpload">
  <form *ngIf="user" [formGroup]="pictureForm" class="post-form">
    <div class="col1">
      <div>
        <label for="file-upload" class="custom-file-upload mat-elevation-z2">
          <mat-icon>photo</mat-icon>
          <span class="mat-h3 margin-unset"> Take or Upload Photo</span>
        </label>
        <input
          id="file-upload"
          type="file"
          #userPhoto
          (change)="onFileSelected($event)"
          name="image"
          autocomplete="off"
          accept="image/*"
        />
      </div>
      <!-- <div>
        <label for="file-upload" class="custom-file-upload">
          <mat-icon>videocamera</mat-icon>

        </label>
        <input
          id="file-upload"
          type="file"
          #userPhoto
          (change)="onFileSelected($event)"
          name="image"
          autocomplete="off"
        />
      </div>
      <div>
        <label for="file-upload" class="custom-file-upload">
          <mat-icon>notes</mat-icon>
        </label>
        <input
          id="file-upload"
          type="file"
          #userPhoto
          (change)="onFileSelected($event)"
          name="image"
          autocomplete="off"
        />
      </div> -->
    </div>
    <img *ngIf="userImagePreview" [src]="userImagePreview" alt="User Upload" class="preview-image" />
    <!-- <video
      *ngIf="urlType === 'video'"
      lazyImage
      mat-card-image
      class="mat-elevation-z2"
      width="100%"
      height="auto"
      controls
      autoplay
      [muted]="'muted'"
      loop
      controlsList="nodownload"
    >
      <source *ngIf="userImagePreview" [src]="userImagePreview" type="video/mp4" /> -->
    <!-- <source src="movie.ogg" type="video/ogg" />
      Your browser does not support the video tag.
    </video>-->
    <mat-progress-bar *ngIf="submitted" [value]="uploadProgress$ | async" mode="determinate"> </mat-progress-bar>
    <mat-card-content *ngIf="userImagePreview">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput placeholder="Photo Title" />
        <mat-error *ngIf="pictureForm.controls['title'].hasError('required')">
          You <strong>SHOULD</strong> title your photo
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <!-- <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}}
          </mat-option> -->
          <mat-option value="fitness">Fitness</mat-option>
          <mat-option value="food">Food</mat-option>
          <mat-option value="social">Social</mat-option>
        </mat-select>
        <mat-error *ngIf="pictureForm.controls['category'].hasError('required')">
          You <strong>SHOULD</strong> select your category
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Description (Optional)</mat-label>
        <textarea formControlName="description" matInput placeholder="Describe your photo"> </textarea>
        <!-- <mat-error *ngIf="pictureForm.controls['description'].hasError('required')">
            You <strong>SHOULD</strong> describe your upload
          </mat-error> -->
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button (click)="postPhoto()" [disabled]="submitted || !pictureForm.valid" color="primary" mat-raised-button>
        Submit Photo
      </button>
    </mat-card-actions>
  </form>
</div>

<!-- Video Form -->
<!-- <div class="col1 vmargin1" *ngIf="videoUpload">
  <form *ngIf="user" [formGroup]="videoForm" class="post-form">
    <div class="col1">
      <div>
        <label for="video-upload" class="custom-file-upload mat-elevation-z2">
          <mat-icon>videocamera</mat-icon>
          <span class="mat-h3 margin-unset">Upload Video</span>
        </label>
        <input
          id="video-upload"
          type="file"
          #userVideo
          (change)="onVideoFileSelected($event)"
          name="video"
          autocomplete="off"
          accept="video/*"
        />
      </div>
    </div>
    <video
      *ngIf="userVideoPreview"
      mat-card-image
      class="mat-elevation-z2"
      width="100%"
      height="auto"
      [muted]="'muted'"
      controls
      loop
      controlsList="nodownload"
      preload="metadata"
    >

      <source *ngIf="userVideoPreview" [src]="userVideoPreview" type="video/mp4" />
      <source *ngIf="userVideoPreview" [src]="userVideoPreview" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <mat-progress-bar *ngIf="submitted" [value]="uploadProgress$ | async" mode="determinate"> </mat-progress-bar>
    <div *ngIf="videoForm.controls['title'].hasError('required')">
      <span class="mat-h4"> Must Be 30 Seconds or Less</span>
    </div>
    <mat-card-content *ngIf="userVideoPreview">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput placeholder="Video Title" />
        <mat-error *ngIf="videoForm.controls['title'].hasError('required')">
          You <strong>SHOULD</strong> label your video
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option value="fitness">Fitness</mat-option>
          <mat-option value="food">Food</mat-option>
          <mat-option value="social">Social</mat-option>
        </mat-select>
        <mat-error *ngIf="videoForm.controls['category'].hasError('required')">
          You <strong>SHOULD</strong> select your category
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Description (Optional)</mat-label>
        <textarea formControlName="description" matInput placeholder="Describe your video"> </textarea>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button (click)="uploadVideo()" [disabled]="submitted || !videoForm.valid" color="primary" mat-raised-button>
        Submit Video
      </button>
    </mat-card-actions>
  </form>
</div> -->

<!-- Posts Form -->
<div class="col1 vmargin1" *ngIf="postsUpload">
  <form *ngIf="user" [formGroup]="postsForm" class="post-form">
    <mat-card-content>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput placeholder="Post Title" />
        <mat-error *ngIf="postsForm.controls['title'].hasError('required')">
          You <strong>SHOULD</strong> title your post
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option value="fitness">Fitness</mat-option>
          <mat-option value="food">Food</mat-option>
          <mat-option value="social">Social</mat-option>
        </mat-select>
        <mat-error *ngIf="postsForm.controls['category'].hasError('required')">
          You <strong>SHOULD</strong> select your category
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Post</mat-label>
        <textarea
          formControlName="description"
          matInput
          rows="8"
          placeholder="Post"
          minlentgth="1"
          maxlength="222"
          #postsDescription
        >
        </textarea>
        <mat-hint>{{ postsDescription?.value?.length }}/222</mat-hint>
        <mat-error *ngIf="postsForm.controls['description'].hasError('required')">
          You <strong>SHOULD</strong> describe your post
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button (click)="uploadPost()" [disabled]="submitted || !postsForm.valid" color="primary" mat-raised-button>
        Submit Post
      </button>
    </mat-card-actions>
  </form>
</div>
