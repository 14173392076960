<ng-container *ngIf="comments">
  <mat-nav-list style="min-height: 100vh; min-width: 100%">
    <div class="col1 header-sticky">
      <div class="col2">
        <span mat-line class="mat-h2 margin1" color="primary">Comments: {{ comments?.length }}</span>
        <button mat-button color="primary" (click)="openLink($event)"><mat-icon>close</mat-icon>Dismiss</button>
      </div>
    </div>
    <div class="col1" *ngFor="let comment of comments">
      <div class="col1">
        <div class="col2">
          <span class="mat-h4 margin-unset">@{{ comment.username }}</span>
          <span class="mat-h4 margin-unset"
            >{{ fromNow(comment.date_created) }}
            <mat-icon color="warn" *ngIf="user.uid === comment.uid" (click)="deleteComment(comment.id)"
              >delete</mat-icon
            ></span
          >
        </div>
        <span class="mat-h3 margin-unset">
          <cite> {{ comment?.text }}</cite>
        </span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <ng-container *ngIf="comments.length <= 0">
      <span class="mat-h2">
        <cite> No Comments. Say Something Nice!</cite>
      </span>
    </ng-container>
    <ng-container *ngIf="loader">
      <div class="col1 margin2">
        <app-loader [isLoading]="loader" [message]="message" [size]="2"></app-loader>
      </div>
    </ng-container>
  </mat-nav-list>

  <div class="sticky">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Enter Comment</mat-label>
      <input matInput placeholder="Say Something..." [(ngModel)]="comment.text" />
      <mat-icon matSuffix (click)="createComment()">send</mat-icon>
      <!-- <mat-error *ngIf="videoForm.controls['title'].hasError('required')">
              You <strong>SHOULD</strong> label your video
            </mat-error> -->
    </mat-form-field>
  </div>
</ng-container>
