import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MEDIA_STORAGE_PATH } from '@app/@shared/const/storage.const';
import { User } from '@app/@shared/interfaces/user';
import { FireCrudService } from '@app/@shared/services/fire-crud.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { StorageService } from '@app/@shared/services/storage.service';
import { AuthenticationService } from '@app/auth';
import { Subject, EMPTY, Observable } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private crudService: FireCrudService<User>;
  destroy$: Subject<null> = new Subject();
  // AngularFirestore should be found by Angular DI System
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private readonly storageService: StorageService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private snack: SnackService
  ) {
    // Let's create our CrudService and use the a Collection with the name 'uploads'
    this.crudService = new FireCrudService<User>(afs, 'users/');
  }

  loadUserProfile(uid: string) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    return userRef.valueChanges({ idField: 'id' });
  }

  async updateProfile(bio: string) {
    let _user = await this.afAuth.currentUser;
    const userData: User = {
      uid: _user.uid,
      email: _user.email,
      photoURL: _user.photoURL,
      displayName: '',
      emailVerified: _user.emailVerified,
      bio: bio,
      isAdmin: false,
    };
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userData.uid}`);

    userRef.set(userData, {
      merge: true,
    });
    return (await this.afAuth.currentUser)
      .updateProfile(userData)
      .then(() => {
        this.snack.open(`Updated Profile!!`, '', 3000);
      })
      .catch((err: any) => {
        this.snack.open(`Failed Updating Profile!!`, '', 3000);
      });
  }

  async deleteProfile() {
    let _confirm = confirm('Delete User').valueOf();
    if (_confirm) {
      this.router.navigateByUrl('login');
      // this.crudService.delete(this.user)
      return (await this.afAuth.currentUser).delete().then((res: any) => {
        alert('Deleted Successfully');
      });
    } else {
      alert('User deleted not confirmed');
    }
  }

  async uploadPhotoUrl(photoUrl: string) {
    let _user = await this.afAuth.currentUser;
    const _date = new Date();
    const date = _date.getTime().toString();
    const userData: User = {
      uid: _user.uid,
      email: _user.email,
      photoURL: photoUrl,
      displayName: _user.displayName,
      emailVerified: _user.emailVerified,
      isAdmin: false,
    };
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${_user.uid}`);

    userRef.set(userData, {
      merge: true,
    });
    return (await this.afAuth.currentUser)
      .updateProfile(userData)
      .then(() => {
        this.snack.open(`Updated Profile Photo!!`, '', 3000);
      })
      .catch((err: any) => {
        this.snack.open(`Failed Updating Profile Photo!!`, '', 3000);
      });
  }
}
