import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Shell } from '@app/shell/shell.service';
import { ProfileComponent } from './profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

const routes: Routes = [
  Shell.childRoutes([{ path: 'profile/:id', component: ProfileComponent, data: { title: marker('Profile') } }]),
  Shell.childRoutes([{ path: 'edit-profile', component: EditProfileComponent, data: { title: marker('My Profile') } }]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ProfileRoutingModule {}
