import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { UploadDetailsComponent } from '@app/uploads/upload-details/upload-details.component';
import * as moment from 'moment';
import { VideoUploadService } from '@app/uploads/services/video-upload.service';
import { PostUploadService } from '@app/uploads/services/post-upload.service';
import { SnackService } from '@app/@shared/services/snack.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommentsComponent } from '@app/home/comments/comments.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '@app/auth';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MEDIA_STORAGE_PATH } from '@app/@shared/const/storage.const';
import { StorageService } from '@app/@shared/services/storage.service';
import { Subject, Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { UtilService } from '@app/@shared/services/util.service';
import { ExploreService } from '@app/home/explore.service';
import { ActivatedRoute } from '@angular/router';
import { FollowService } from '../follow.service';
import { ProfileService } from '../profile.service';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  @Output() logoutClick: EventEmitter<null> = new EventEmitter<null>();
  destroy$: Subject<null> = new Subject();
  _user: BehaviorSubject<any> = new BehaviorSubject<any>({});
  unsubscribe$: Subject<boolean> = new Subject();
  user: any;
  userFeed: any = [];
  photos: any = [];
  videos: any = [];
  posts: any = [];
  displayName: any;
  bio: any;
  enable_edit: boolean = false;
  loader = false;
  message = 'Loading Feed';
  query: any;
  photosLoaded: boolean = false;
  videosLoaded: boolean = false;
  postsLoaded: boolean = false;
  public searchFilter: any = '';
  pictureForm: FormGroup;
  fileToUpload: File;
  userImagePreview: string | ArrayBuffer;
  submitted = false;
  uploadProgress$: Observable<number>;
  profileId: any;
  isFollowing = false;
  enableEditBtn: boolean = false;
  constructor(
    private exploreService: ExploreService,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
    private videoService: VideoUploadService,
    private postsService: PostUploadService,
    private profileService: ProfileService,
    private readonly storageService: StorageService,
    private utilService: UtilService,
    private snack: SnackService,
    private afs: AngularFirestore,
    private _bottomSheet: MatBottomSheet,
    private readonly formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private followService: FollowService,
    private _activatedRoute: ActivatedRoute
  ) {
    // this.authService.getProfileObs().subscribe((u) => console.log('Profile obs', (this.user = u)));
    this.authService.getProfileObs().subscribe((u) => console.log('Profile obs', (this.user = u)));
    if (this.user) {
      this.displayName = this.user.displayName;
      this.bio = this.user.bio ? this.user.bio : 'Fill Out Your Bio...';
      this.loadPhotos();
    } else {
      return;
    }
  }

  ngOnInit(): void {
    this.photosLoaded = true;

    this.pictureForm = this.formBuilder.group({
      photo: [null, [Validators.required, this.image.bind(this)]],
    });

    this.pictureForm
      .get('photo')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  openBottomSheet(upload: any, type: any): void {
    this._bottomSheet.open(CommentsComponent, {
      data: {
        type,
        upload,
      },
    });
  }
  logout() {
    this.logoutClick.emit();
  }

  openUpload(id: any) {
    console.log(id);
    this.dialog.open(UploadDetailsComponent, {
      data: {
        id: id,
      },
    });
  }

  enableEdit() {
    this.enable_edit = !this.enable_edit;
    this.enableEditBtn = !this.enableEditBtn;
  }

  updateProfile() {
    // Possible implement checking already existing display names
    this.profileService.updateProfile(this.bio).then((res: any) => {
      console.log(res);
      this.enable_edit = !this.enable_edit;
    });
  }

  deleteProfile() {
    // Prompt confirm snackbar or modal before deleteing
    this.profileService.deleteProfile();
  }

  deleteUpload(id: string) {
    const _confirm = confirm('Delete Upload').valueOf();
    if (_confirm) {
      this.exploreService.deleteUpload(id);
    } else {
      alert('Cancelled');
    }
  }

  fromNow(_d: any): string {
    _d = Number(_d);
    const parsedDate = moment(_d).format('DD MMM YYYY hh:mm a');
    return moment(parsedDate).fromNow();
  }

  parseDisplayEmail(email: any): string {
    return email.split('@')[0];
  }

  loadPhotos() {
    this.loader = true;
    this.postsLoaded = false;
    this.videosLoaded = false;
    this.userFeed = [];
    this.exploreService.getAllUploads().subscribe((r: any) => {
      this.photosLoaded = true;
      this.loader = false;
      console.log(r);
      this.userFeed = r.filter((el: any) => el.uid === this.user.uid);
    });
  }
  loadVideos() {
    this.loader = true;
    this.photosLoaded = false;
    this.postsLoaded = false;
    this.userFeed = [];
    this.videoService.getAllVideos().subscribe((r) => {
      this.videosLoaded = true;
      console.log(r);
      this.loader = false;
      this.userFeed = r.filter((el) => el.uid === this.user.uid);
    });
  }
  loadPosts() {
    this.loader = true;
    this.photosLoaded = false;
    this.videosLoaded = false;
    this.userFeed = [];
    this.postsService.getAllPosts().subscribe((r) => {
      this.postsLoaded = true;
      console.log(r);
      this.loader = false;
      this.userFeed = r.filter((el) => el.uid === this.user.uid);
    });
  }

  deleteVideo(video: any) {
    this.videoService.deleteVideo(video);
  }

  deletePost(post: any) {
    const _confirm = confirm('Delete Upload').valueOf();
    if (_confirm) {
      this.postsService.deletePost(post);
    } else {
      this.snack.open('Your Upload is Safe', '', 2000);
    }
  }

  handleFileChange([uploadImage]: any) {
    this.fileToUpload = uploadImage;
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      // if (
      //   loadEvent.target.result
      //     .toString()
      //     .match(/(?:https?|ftp):\/\/[\S]*\.(?:png|jpe?g|gif|svg|webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)
      // ) {
      //   this.urlType = 'image';
      // }
      // else if (
      //   loadEvent.target.result
      //     .toString()
      //     .match(/(?:https?|ftp):\/\/[\S]*\.(?:mp4|ogg|MOV)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)
      // ) {
      //   this.urlType = 'movie';
      // }
      console.log(loadEvent.target.result);
      this.userImagePreview = loadEvent.target.result;
    };

    reader.readAsDataURL(uploadImage);
  }

  async postPhoto() {
    this.submitted = true;
    const mediaFolderPath = `${MEDIA_STORAGE_PATH}/${this.user.email}/photos/`;

    const { downloadUrl$, uploadProgress$ } = this.storageService.uploadFileAndGetMetadata(
      mediaFolderPath,
      this.fileToUpload
    );

    this.uploadProgress$ = uploadProgress$;
    downloadUrl$
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.snack.open(`${error.message} 😢`, 'Close', 4000);
          return EMPTY;
        })
      )
      .subscribe((downloadUrl) => {
        console.log(downloadUrl);
        // parseUrl(url: string) {
        // if (downloadUrl.match(/(?:https?|ftp):\/\/[\S]*\.(?:png|jpe?g|gif|svg|webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)) {
        //   this.urlType = 'image';
        // } else if (downloadUrl.match(/(?:https?|ftp):\/\/[\S]*\.(?:mp4|ogg|MOV)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi)) {
        //   this.urlType = 'movie';
        // }
        // }

        this.profileService.uploadPhotoUrl(downloadUrl);
        this.submitted = false;
      });
  }
  onFileSelected(event: any) {
    this.handleFileChange([event.target.files[0]]);
    this.pictureForm.value.photo = event.target.files[0];
    this.pictureForm.valid;
    console.log(this.image.bind(this));
    this.pictureForm.patchValue({
      photo: [null, [Validators.required, this.image.bind(this)]],
    });
    // this.authService.afAuth.user.pipe(takeUntil(this.destroy$)).subscribe((user: any) => (this.user = user));

    this.pictureForm
      .get('photo')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
      });
    console.log(this.pictureForm.value);
  }

  private image(photoControl: AbstractControl): { [key: string]: boolean } | null {
    if (photoControl.value) {
      console.log(photoControl);
      const [uploadImage] = photoControl.value.files;
      return this.utilService.validateFile(uploadImage)
        ? null
        : {
            image: true,
          };
    }
    return;
  }
}
