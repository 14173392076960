<div fxFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.5rem" class="mat-typography">
  <div class="vmargin2">
    <h1 fxLayoutAlign="center" translate>Forgot Password</h1>
  </div>
  <div class="forgotPW-container" fxLayout="row" fxLayoutAlign="center">
    <mat-card fxFlex="90%" fxFlex.sm="66%" fxFlex.md="50%" fxFlex.gt-md="33%" class="forgotPW-box">
      <form (ngSubmit)="sendEmail()" [formGroup]="forgotPW" novalidate>
        <div class="forgotPW-error" [hidden]="!error || isLoading" translate>Email or password incorrect.</div>
        <br />
        <div class="forgotPW-fields" fxLayout="column">
          <mat-form-field [hideRequiredMarker]="true">
            <input
              type="text"
              matInput
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              #email
              formControlName="email"
              autocomplete="email"
              [placeholder]="'Email' | translate"
              required
            />
            <mat-error *ngIf="email['errors']?.pattern">Not a valid email!</mat-error>
            <mat-error *ngIf="forgotPW.controls['email'].invalid && forgotPW.controls['email'].touched">
              <span translate>Valid email is required</span>
            </mat-error>
          </mat-form-field>

          <button mat-raised-button color="primary" type="submit" [disabled]="forgotPW.invalid || isLoading">
            <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
            <span translate>Send Magic Link</span>
          </button>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1.5rem">
            <button mat-flat-button (click)="login()" type="button" routerLink="/forgotPW">Go back to login?</button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
